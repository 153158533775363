import { ManualModalAlertUI } from './ManualModalAlert.Presenter';
import { useRecoilState } from 'recoil';
import { ManualModalAlertDataType, manualModalAlertData, closeManualModalAlert } from 'recoil/manualModalAlert';
import React, { useCallback } from 'react';

export const ManualModalAlertContainer = React.memo(function ManualModalAlertContainer() {
  const [manualModalAlert, setManualModalAlert] = useRecoilState<ManualModalAlertDataType>(manualModalAlertData);

  const onClose = useCallback(() => {
    closeManualModalAlert({ setManualModalAlert });
  }, []);

  const onClicChatbotButton = useCallback(() => {
    window.open('https://longedu.channel.io/home', '_blank');
  }, []);

  return <ManualModalAlertUI {...manualModalAlert} onClose={onClose} onClicChatbotButton={onClicChatbotButton} />;
});
