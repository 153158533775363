import { Suspense, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './mui/themes';
import { ModalAlertContainer } from 'components/common/ModalAlert/ModalAlert.Container';
import { ModalConfirmContainer } from 'components/common/ModalConfirm/ModalConfirm.Container';
import { ModalCheckContainer } from 'components/common/ModalCheck/ModalCheck.Container';
import { ModalRadioContainer } from 'components/common/ModalRadio/ModalRadio.Container';
import { ModalCalendarContainer } from 'components/common/ModalCalendar/ModalCalendar.Container';
import { ModalAddTeacherContainer } from 'components/common/ModalAddTeacher/ModalAddTeacher.Container';
import { UploadModalContainer } from 'components/common/UploadModal/UploadModal.Container';
import { ModalUpAndDownContainer } from 'components/common/ModalUpAndDown/ModalUpAndDown.Container';
import { LoadingContainer } from 'components/common/Loading/Loading.Container';
import { ModalDefaultProfileSettingContainer } from 'components/common/ModalDefaultProfileSetting/ModalDefaultProfileSetting.Container';
import { LoadingWithRecoilContainer } from 'components/common/LoadingWithRecoil/LoadingWithRecoil.Container';
import { retryLazy } from 'utils/tools';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { UserInfoType, userInfoState } from 'recoil/userInfo';

import { deviceState } from 'recoil/device';
// import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
// import { setMissionDocs, checkChangeMissionStep } from 'utils/firebase';

declare let window: any;
import { ManualModalAlertContainer } from 'components/common/ManualModalAlert/ManualModalAlert.Container';

const DefaultContainer = retryLazy(() =>
  import('pages/Default/Default.Container').then(module => ({ default: module.DefaultContainer })),
);

declare global {
  let $: any;
}

function App() {
  const setDeviceStateData = useSetRecoilState(deviceState);
  const userInfo = useRecoilValue<UserInfoType>(userInfoState);
  const { id } = userInfo;
  const accountIdRef = useRef(id);
  accountIdRef.current = id;

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', keyDown);

    const card_open = localStorage.getItem('card_open');
    if (!card_open) {
      localStorage.setItem('card_open', JSON.stringify({}));
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', keyDown);
    };
  }, []);
  const handleResize = () => {
    setDeviceStateData(prevState => ({
      ...prevState,
      screen_width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      screen_height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    }));
  };
  const keyDown = (e: KeyboardEvent) => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode == 80) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingContainer isLogo={true} />}>
          <Routes>
            <Route path={'/*'} element={<DefaultContainer />} />
            {/* <Route path={'/login'} element={<LoginContainer />} /> */}
          </Routes>
        </Suspense>
        <ManualModalAlertContainer />
        <ModalAlertContainer />
        <ModalConfirmContainer />
        <ModalRadioContainer />
        <ModalCheckContainer />
        <ModalCalendarContainer />
        <ModalAddTeacherContainer />
        <UploadModalContainer />
        <ModalUpAndDownContainer />
        <ModalDefaultProfileSettingContainer />
        <LoadingWithRecoilContainer />
      </ThemeProvider>
    </Box>
  );
}

export default App;
